<template>
  <div class="goods-lists">
    <div class="common-list-title">
      <div class="title-name">{{ titleName }}</div>
    </div>
    <div class="list-box">
      <div
        @click.stop="toGoodDetail(item)"
        v-for="(item, index) in goodsLists"
        :key="index + 'a'"
        class="list-item point">
        <div class="item-img">
          <img class="w100" :src="item.image" alt="" srcset="" />
        </div>
        <div class="name">{{ item.title }}</div>
        <div class="item-bottom flex-b">
          <div class="bottom-price text-c">
            <div>${{ item.marketprice }}</div>
            <div>price</div>
          </div>
          <div class="bottom-vip-price text-c">
            <div>${{ item.price }}</div>
            <div>VIP</div>
            <div class="img">
              <img
                class="w100 h100"
                src="@/assets/images/pc/pc-home-icon12.png"
                alt=""
                srcset="" />
            </div>
          </div>
          <div
            class="bottom-add flex-c point"
            @click.stop="addToCarOnClick(item)">
            ADD TO CART
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mallGoodsListApi, addCarApi } from "@/network/api.js";
import { mapActions, mapMutations } from "vuex";
export default {
  name: "office",
  // 配置title和meta数据
  metaInfo: {
    title: "WinOfficeKey",
    meta: [
      {
        name: "keywords",
        content: "ACE keys Office Windows Microsoft Online",
      },
      {
        name: "description",
        content: "ACE keys Office Windows Microsoft Online",
      },
    ],
  },
  data() {
    return {
      titleName: "office",
      goodsLists: [],
      activeType: "",
      productId: "",
    };
  },
  created() {
    this.getGoodsList();
  },
  watch: {
    $route: {
      handler() {
        this.productId = this.$route.meta?.id;
        if (this.productId == "39") {
          this.titleName = "Office";
        } else if (this.productId == "40") {
          this.titleName = "Windows";
        } else {
          this.titleName = "Phone Key";
        }
        this.getGoodsList();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["getCartList"]),
    ...mapMutations(["setShowTipPopup", "setShowTopCart"]),
    async getGoodsList() {
      const params = {
        type: "marketprice",
        sort: "asc",
        page: 1,
        category_id: this.productId,
      };
      if (this.productId == "888") {
        params.category_id = "";
      }
      const res = await mallGoodsListApi(params);
      // this.TotalPages = res.data.TotalPages;
      this.goodsLists = res.data.Items;
    },
    async addToCarOnClick(item, isNum) {
      const params = {
        goods_id: item.id,
        nums: isNum ? this.goodNum : 1,
      };
      try {
        const res = await addCarApi(params);
        this.$message.success("Added successfully");
        await this.getCartList();
        this.setShowTopCart(true);
        console.log("添加到购物车", item);
        // this.$router.push({ name: "Cart", params: { item } });
      } catch (error) {
        this.setShowTipPopup(true);
      }
    },
    toGoodDetail(item) {
      console.log(11111, item);
      // return;
      this.activeType = item.id;
      this.page = 1;
      this.$router.push({
        path: `/products/${item.keywords}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-lists {
  padding: 1.1rem 3.23rem 1.8rem;
  .common-list-title {
    height: 0.91rem;
    display: flex;
    align-content: space-between;
    justify-content: center;
    flex-wrap: wrap;
    .title-name {
      width: 100%;
      text-align: center;
      font-size: 0.32rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
    }
  }
  .list-box {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    flex-wrap: wrap;
    .list-item {
      width: 2.7rem;
      flex-shrink: 0;
      margin-bottom: 0.75rem;
      padding: 0.2rem;

      .item-img {
        width: 100%;
        margin-bottom: 0.24rem;
      }
      .name {
        margin-bottom: 0.31rem;
        font-size: 0.18rem;
        height: 3em;
      }
      .item-bottom {
        .bottom-add {
          width: 1.26rem;
          height: 0.44rem;
          flex-shrink: 0;
          background: #ffffff;
          box-shadow: 0px 0.04rem 0.04rem 0px rgba(0, 0, 0, 0.1);
          border-radius: 0.04rem;
          border: 1px solid rgba(0, 0, 0, 0.1);
          font-size: 0.12rem;
          font-weight: 900;
        }
        .bottom-add:hover {
          background: #5ba7ff;
          color: #fff;
        }
      }
    }
  }
  .list-box::after {
    content: "";
    width: 4.27rem;
  }
}
</style>
